import React, {Component} from 'react';
import './Home.css';

let _this;

class Products extends Component {
    constructor(props, context) {
        super(props, context);
        _this = this;

    }

    render() {
        return <div className="container">
            <br/>
            <div className="card" id="shellPrimaryNoPadding">
                <div className="fast_nav container">
                    <ul>
                        <li className="first">
                            <a href="https://upwriteai.com">Up Write AI</a>
                        </li>
                        <li>
                            <a href="https://wisetalkapp.com">WiseTalk</a>
                        </li>
                        <li>
                            <a href="https://acallrecorder.com">A Call Recorder</a>
                        </li>
                        <li>
                            <a href="https://studycardsapp.com">StudyCards</a>
                        </li>
                        <li>
                            <a href="https://photodj.me">PhotoDj</a>
                        </li>
                        <li>
                            <a href="#babyTube" role="button"
                               onClick={() => _this.props.navigate('BabyTube')}>BabyTube</a>
                        </li>
                        <li>
                            <a href="#BabyMonitor" role="button"
                               onClick={() => _this.props.navigate('BabyMonitor')}>Baby Monitor</a>
                        </li>
                        <li>
                            <a href="#support" role="button"
                               onClick={() => _this.props.navigate('Support')}>Support</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card" id="shell4">
                <div id="content">
                    <p>
                        <i>We make great products that make people's life a bit better.</i>
                    </p>
                    <p>
                        <i>Our apps and services will help you find the answers to some of your questions and solutions
                            to some of your problems.</i>
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shell4">
                <div id="content">
                    <p>
                        <div>
                            <img style={{borderRadius: '10%'}} className="roundrect" src="/upwrite192.png" align="left"
                                 hspace="10" vspace="10" alt="app icon"
                                 width="128px"/>
                            <p>
                                <br/>
                                <h2>Up Write AI: Proofreading Keyboard</h2>
                                <h3>Writing assistance tool that uses AI to identify and correct grammar, spelling,
                                    punctuation, and style errors in text.</h3>
                            </p>
                        </div>
                    </p>
                    <br/>
                    <p>
                        UpWrite AI Editor & Keyboard is a digital writing assistance tool that uses artificial
                        intelligence and natural language processing to identify and correct grammar, spelling,
                        punctuation, and style errors in text. Additionally, it can adjust the tone, clarity,
                        engagement, and delivery of a piece of writing. It's specifically designed for mobile users on
                        both iOS and Android platforms. This tool is ideal for writers, students, professionals, and
                        anyone looking to enhance the quality and clarity of their written communication. With a focus
                        on mobile optimization and voice-to-text (dictation) capabilities, UpWrite AI stands out as a
                        premier choice for those seeking top-tier writing assistance on the go.
                    </p>
                    <p>
                        Key Features:
                        <ul>
                            <li> Advanced Text Editing: Ability to mold your text to desired tones, styles, and
                                audiences, ensuring versatility in your communication.
                            </li>
                            <li> Proofreading Keyboard Extension: Works harmoniously across all apps on your phone,
                                ensuring consistent quality in all your writings. Is an embedded companion keyboard
                                extension that works in Mail, Messenger, Messages, LinkedIn, X (Twitter), Gmail, etc.
                            </li>
                            <li> In-App Voice Input: A voice-to-text tool designed for those on-the-go moments,
                                transforming your spoken words into polished written content.
                            </li>
                            <li> Educative Corrections: Understand your mistakes as UpWrite AI not only corrects but
                                also explains, fostering continuous improvement in your writing skills.
                            </li>
                        </ul>
                        While most competing apps tie you down with subscriptions, we believe in a 'Pay as you Go'
                        philosophy. No hidden fees. No automatic renewals. We prioritize your needs, giving you the
                        power to decide when and for what you pay. Experience the freedom of only paying for what you
                        actually use.
                    </p>
                    <br/>
                    <div className="row">
                        <div className="column">
                            <img src="/upwrite_screens.png" alt="screenshot 1" width="100%"/>
                        </div>
                    </div>
                    <br/>
                    <div align="center" hspace="10" vspace="10">
                        <a href="https://apps.apple.com/us/app/upwrite-ai-proofread-keyboard/id6463730152"
                           target="_blank"
                           rel="noopener">
                            <img src="/app_store.png" alt="itunes" height="32px" hspace="5" vspace="5"/>
                        </a>
                        <a href="https://apps.apple.com/us/app/upwrite-proofreads-your-texts/id6467161876"
                           target="_blank"
                           rel="noopener">
                            <img src="/Mac_App_Store.svg" alt="macappstore" height="32px" hspace="5" vspace="5"/>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.answersolutions.upwriteai"
                           target="_blank"
                           rel="noopener">
                            <img src="/google_play.svg" alt="google play" height="32px" hspace="5" vspace="5"/>
                        </a>
                        <a href="https://chrome.google.com/webstore/detail/upwrite-ai-proofreading-t/dfmkhghfmnmpclilkafacfjoajlomjeo"
                           target="_blank"
                           rel="noopener">
                            <img src="/chrome_web_store.png" alt="chrome web store" height="32px" hspace="5"
                                 vspace="5"/>
                        </a>
                    </div>
                    <div align="left">
                        <a href="https://www.producthunt.com/products/upwrite-ai-proofreading-keyboard-more"
                           target="_blank"
                           rel="noopener">
                            <img alt="ph" src="https://api.producthunt.com/widgets/embed-image/v1/follow.svg"
                                 height="32px" hspace="5" vspace="5"/>
                        </a>
                    </div>
                    <div align="center">
                        <a href="https://upwriteai.com">www.upwriteai.com</a>
                    </div>
                </div>
            </div>
            <br/>
            <div className="card" id="shell4">
                <div id="content">
                    <p>
                        <div>
                            <img style={{borderRadius: '10%'}} className="roundrect" src="/wt_logo192.png" align="left"
                                 hspace="10" vspace="10" alt="app icon"
                                 width="128px"/>
                            <p>
                                <br/>
                                <h2>WiseTalk App</h2>
                            </p>
                            <p><h3>Voice-Activated, Portable ChatGPT powered Assistant.</h3></p>
                        </div>
                    </p>
                    <br/>
                    <p>
                        Voice-activated chat assistant powered by cutting-edge Artificial Intelligence!
                        Multilingual support!
                        Experience nearly hands-free AI conversations anywhere!

                        WiseTalk: Engaging AI Conversations, Effortlessly Nearly Hands-Free

                        Discover the next generation of AI interactions with WiseTalk—an innovative app that seamlessly
                        blends cutting-edge Large Language Model based AI with speech-to-text and text-to-speech
                        technology to deliver a uniquely engaging and natural conversational experience.
                    </p>
                    <p>
                        Key Features:
                        <ul>
                            <li> Voice-Based AI Interactions: WiseTalk harnesses speech-to-text and text-to-speech
                                engines to facilitate intuitive, voice-driven conversations with an advanced AI language
                                model.
                            </li>
                            <li> Real-Time Assistance: Obtain real-time advice, information, and support across a wide
                                array of topics, making AI wisdom accessible wherever you are.
                            </li>
                            <li> Local Speech Processing: WiseTalk prioritizes user privacy and convenience, performing
                                speech recognition and synthesis directly on your device—no need to send voice data to
                                the cloud.
                            </li>
                            <li> Reliable Connectivity: Designed for optimal performance in areas with poor internet
                                connections, WiseTalk exchanges text-only data to ensure a smooth user experience.
                            </li>
                            <li> Multilingual Support: WiseTalk embraces a global audience with its ability to recognize
                                and synthesize any language supported by your device.
                            </li>
                            <li> Customizable AI Roles: Tailor the AI's response style to your needs, from simple
                                explanations by an elementary school teacher to in-depth analyses by a college
                                professor.
                            </li>
                            <li> Token-Based Access: Enjoy an initial 10K tokens for free, and refill your balance with
                                affordable token packages. Get even more value with our subscription service, doubling
                                your tokens and providing access to conversation history, and more.
                            </li>
                        </ul>
                        Experience the future of AI-driven conversations with WiseTalk, and unlock the full potential of
                        voice-based interactions on your Apple device.
                        Download WiseTalk today and join the AI conversation revolution.
                    </p>
                    <br/>
                    <div className="row">
                        <div className="column">
                            <img src="/screenshots.png" alt="screenshot 1" width="100%"/>
                        </div>
                    </div>
                    <br/>
                    <div align="center" hspace="10" vspace="10">
                        <a href="https://apps.apple.com/us/app/wisetalk-ai-powered-voice-chat/id6447212540"
                           target="_blank"
                           rel="noopener">
                            <img src="/app_store.png" alt="itunes" height="32px" hspace="5" vspace="5"/>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.answersolutions.talkwise"
                           target="_blank"
                           rel="noopener">
                            <img src="/google_play.svg" alt="google play" height="32px" hspace="5" vspace="5"/>
                        </a>
                    </div>
                    <div align="left">
                        <a href="https://www.producthunt.com/products/wisetalk-voice-activated-ai-companion"
                           target="_blank"
                           rel="noopener">
                            <img alt="ph" src="https://api.producthunt.com/widgets/embed-image/v1/follow.svg"
                                 height="32px" hspace="5" vspace="5"/>
                        </a>
                    </div>
                    <div align="center">
                        <a href="https://wisetalkapp.com">www.wisetalkapp.com</a>
                    </div>
                </div>
            </div>
            <br/>
            <div className="card" id="shell4">
                <div id="content">
                    <p>
                        <div>
                            <img style={{borderRadius: '10%'}} className="roundrect" src="/acra.png" align="left"
                                 hspace="10" vspace="10" alt="app icon"
                                 width="128px"/>
                            <p>
                                <br/>
                                <h2>A Call Recorder App</h2>
                            </p>
                            <p><h3>Records Regular Phone Calls in the BEST Possible Quality.<br/>Transforms Recorded
                                Audio files into Readable Text Documents.</h3></p>
                        </div>
                    </p>
                    <br/>
                    <p>
                        A Call Recorder is one of the best ways to <strong>record a regular phone
                        call</strong> conversation. We use the
                        <strong>IVR</strong> (Interactive Voice Response) technology to record the phone call
                        conversation in the cloud
                        with the best possible quality.
                    </p>
                    <p>
                        Also, we use the <strong>Best-In-Class ML/AI</strong> (machine learning and artificial
                        intelligence) engine to
                        <strong>transcribe phone calls</strong> in case you need to transform recorded audio files into
                        readable text that includes speaker separation, time codes, and more.
                    </p>
                    <p>
                        <strong>!!The USA And Canada Phones Only!!</strong><br/>
                        Currently, our app and service work for (+1) country code.<br/>
                        However, you can call any country and record phone conversations through the <strong>'Merge And
                        Call'</strong> button. For more information, please read the user guide on our website.

                    </p>
                    <p>
                        <strong>!!Transcription properly works for English, Spanish, and French!!</strong><br/>
                        The App will record a call in any existing language.<br/>
                        However, for now, we can properly transcribe only English, Spanish, and French.
                    </p>
                    <p>
                        <b>A Call Recorder App</b> lets you:
                        <ul>
                            <li> Record phone calls in perfect quality.</li>
                            <li> Record incoming or outgoing calls or calls you are already on, even if you are in
                                headphones.
                            </li>
                            <li> Record conference call (if your plane and the conference support merging lines).
                            </li>
                            <li> Get timestamped transcription of the recorded phone call conversation if needed.</li>
                            <li> Share recorded audio files and transcribed conversations as a text document.</li>
                            <li> Simple pricing and billing without any hidden fees.</li>
                        </ul>
                    </p>
                    <p>
                        Very simple:
                        <ul>
                            <li> Once you download the app, you receive <strong>60</strong> Credit Minutes.</li>
                            <li> SignUp with your Phone Number.</li>
                            <li> Tap the <strong>'Record A Call'</strong> button and choose or dial the destination
                                number.
                            </li>
                            <li> Tap the <strong>'Merge And Record'</strong> button on the bottom left if you are
                                already on the call.
                            </li>
                            <li> Go to the history of recorded calls and playback the recorded audio files whenever you
                                want.
                            </li>
                            <li> Request Transcription to transform recorded audio files into readable text documents.
                            </li>
                            <li> Share audio and text files through email.</li>
                            <li> Purchase extra minutes when you need them.</li>
                        </ul>
                    </p>
                    <p>
                        <strong>
                            <span className="crimson_text">!!No Ads!!</span><br/>
                            <span className="crimson_text">!!No Subscription!!</span>
                        </strong>
                    </p>
                    <p>
                        Mobile app is available for modern Apple and Android phones.
                    </p>
                </div>
                <div className="row">
                    <div className="column">
                        <img src="/screen1.webp" alt="screenshot 1" width="100%"/>
                    </div>
                    <div className="column">
                        <img src="/screen2.webp" alt="screenshot 2" width="100%"/>
                    </div>
                    <div className="column">
                        <img src="/screen3.webp" alt="screenshot 3" width="100%"/>
                    </div>
                    <div className="column">
                        <img src="/screen4.webp" alt="screenshot 4" width="100%"/>
                    </div>
                </div>
                <div align="center" hspace="10" vspace="10">
                    <a href="https://apps.apple.com/us/app/a-call-recorder/id1622051987"
                       target="_blank"
                       rel="noopener">
                        <img src="/app_store.png" alt="itunes" height="32px" hspace="5" vspace="5"/>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.answersolutions.recordacall"
                       target="_blank"
                       rel="noopener">
                        <img src="/google_play.svg" alt="google play" height="32px" hspace="5" vspace="5"/>
                    </a>
                </div>
                <div align="left">
                    <a href="https://www.producthunt.com/products/a-call-recorder-transcriber-1-only"
                       target="_blank"
                       rel="noopener">
                        <img alt="ph" src="https://api.producthunt.com/widgets/embed-image/v1/follow.svg width="
                             height="32px" hspace="5" vspace="5"/>
                    </a>
                </div>
                <div align="center">
                    <a href="https://acallrecorder.com">www.acallrecorder.com</a>
                </div>
            </div>
            <br/>
            <div className="card" id="shell4">
                <div id="content">
                    <div>
                        <img style={{borderRadius: '10%'}} src="/study_cards.png" align="left" hspace="10" vspace="10"
                             alt="app icon"
                             width="128px"/>
                        <br/>
                        <p align="left"><h2>StudyCards app helps memorize things!</h2></p>
                        <p align="left"><h3>Flashcards Make Study Easy</h3></p>
                        <p align="left">
                            <body>StudyCards is the easiest way to make your own Flashcards and memorize everything you
                            want!
                            You can study by swiping the cards one by one.
                            Or, you can LISTEN on repeat!
                            </body>
                        </p>
                    </div>
                    <div className="row">
                        <div className="column">
                            <img src="/sc1.png" alt="screenshot 1" width="100%"/>
                        </div>
                        <div className="column">
                            <img src="/sc2.png" alt="screenshot 2" width="100%"/>
                        </div>
                        <div className="column">
                            <img src="/sc3.png" alt="screenshot 3" width="100%"/>
                        </div>
                    </div>
                </div>
                <div align="center">
                    <a href="https://apps.apple.com/us/app/study-cards-help-to-memorize/id1436913731"
                       target="_blank"
                       rel="noopener">
                        <img src="/app_store.png" alt="itunes" height="32px" hspace="5" vspace="5"/>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.nes.studycards"
                       target="_blank"
                       rel="noopener">
                        <img src="/google_play.svg" alt="google play" height="32px" hspace="5" vspace="5"/>
                    </a>
                </div>
                <div align="left">
                    <a href="https://www.producthunt.com/products/studycards-app-talking-flashcards"
                       target="_blank"
                       rel="noopener">
                        <img alt="ph" src="https://api.producthunt.com/widgets/embed-image/v1/follow.svg width="
                             height="32px" hspace="5" vspace="5"/>
                    </a>
                </div>
                <div align="center">
                    <a href="https://studycardsapp.com/">www.studycardsapp.com</a>
                </div>
            </div>
            <br/>
            <div className="card" id="shell4">
                <div id="content">
                    <div>
                        <img style={{borderRadius: '10%'}} src="/photo_dj.png" align="left" hspace="10" vspace="10"
                             alt="app icon" width="128px"/>
                        <br/>
                        <p align="left"><h2>PhotoDj - Photos on Big Screen</h2></p>
                        <p align="left"><h3>Slideshow on a Bigger Screen</h3></p>
                        <p align="left">
                            <body>Show photos to your friends, family, or TEAM on a Big Screen!
                            No Account or registration is needed!
                            PhotoDj DOES NOT send your photos to Server or Cloud!
                            Uses WiFi only!
                            </body>
                        </p>
                        <div className="preview">
                            <img src="/pj1.png" align="bottom" alt="photodj preview" width="100%"/>
                        </div>
                    </div>
                    <div align="right" hspace="10" vspace="10">
                        <a href="https://apps.apple.com/to/app/photodj-your-photos-on-tv/id1325400635?platform=appleTV"
                           target="_blank"
                           rel="noopener">
                            <img src="/apple_tv.png" alt="itunes" height="32px" hspace="5" vspace="5"/>
                        </a>
                        <a href="https://itunes.apple.com/us/app/photodj/id1325400635?ls=1&amp;mt=8"
                           target="_blank"
                           rel="noopener">
                            <img src="/app_store.png" alt="itunes" height="32px" hspace="5" vspace="5"/>
                        </a>

                        <a href="https://play.google.com/store/apps/details?id=com.nes.pdj.photodj" target="_blank"
                           rel="noopener">
                            <img src="/google_play.svg" alt="google play" height="32px" hspace="5" vspace="5"/>
                        </a>
                    </div>
                    <div align="left">
                        <a href="https://www.producthunt.com/posts/photodj-present-gallery-on-a-big-screen?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-photodj&#0045;present&#0045;gallery&#0045;on&#0045;a&#0045;big&#0045;screen"
                           target="_blank"><img
                            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=423021&theme=light"
                            alt="PhotoDJ&#0058;&#0032;Present&#0032;Gallery&#0032;on&#0032;a&#0032;Big&#0032;Screen - Transform&#0032;photos&#0032;from&#0032;your&#0032;phone&#0032;gallery&#0032;into&#0032;a&#0032;presentation | Product Hunt"
                            height="32px" hspace="5" vspace="5"/></a>
                    </div>
                    <div align="center">
                        <a href="https://photodj.me/">www.photodj.me</a>
                    </div>
                </div>
            </div>
            <br/>
            <div className="card" id="shell4">
                <div id="content">
                    <div>
                        <img style={{borderRadius: '10%'}} src="/airstickies.png" align="left" hspace="10" vspace="10"
                             alt="app icon"
                             width="128px"/>
                        <br/>
                        <p align="left"><h2>AirSync for Stickies</h2></p>
                        <p align="left"><h3>Get the stickies from your Mac</h3></p>
                        <p align="left">
                            <body>AirSync uploads the regular MacOS's Stickies to your mobile phone using
                            WiFi/iCloud/Dropbox.
                            The AirSync app backs your stickies up in iCloud or Dropbox!
                            </body>
                        </p>
                        <div className="preview">
                            <img src="/as1.png" align="bottom" alt="airsync preview" width="100%"/>
                        </div>
                    </div>
                    <div align="right" hspace="10" vspace="10">
                        <a href="https://apps.apple.com/us/app/airsync-for-stickies/id543513150"
                           target="_blank"
                           rel="noopener">
                            <img src="/Mac_App_Store.png" alt="itunes" height="32px" hspace="5" vspace="5"/>
                        </a>
                        <a href="https://apps.apple.com/us/app/airsync-your-stickies/id554182458"
                           target="_blank"
                           rel="noopener">
                            <img src="/app_store.png" alt="itunes" height="32px" hspace="5" vspace="5"/>
                        </a>

                        <a href="https://play.google.com/store/apps/details?id=com.umnes.stickies2go" target="_blank"
                           rel="noopener">
                            <img src="/google_play.svg" alt="google play" height="32px" hspace="5" vspace="5"/>
                        </a>
                    </div>
                </div>
            </div>
            <br/>
            <div className="card" id="shell4">
                <div id="content">
                    <div>
                        <img style={{borderRadius: '10%'}} src="/app_baby_watcher.png" align="left" hspace="10"
                             vspace="10" alt="app icon"
                             width="128px"/>
                        <br/>
                        <p align="left"><h2>Baby Monitor And Camera WiFi</h2></p>
                        <p align="left"><h3>Look after what is important to you! One phone is a monitor, 2nd is a
                            camera!</h3></p>
                        <p align="left">
                            <body>
                            Baby Watcher App is a Wi-Fi Camera and Monitor that lets you use any two phones to Keep an
                            eye on what is important to you.
                            You need two devices and the App installed on each device.
                            One phone is a monitor, and the second is a remotely controlled camera.
                            </body>
                        </p>
                    </div>
                    <div className="row">
                        <div className="column">
                            <img src="/BabyWatcher.playstore.banner.png" alt="screenshot 1" width="100%"/>
                        </div>
                    </div>
                </div>
                <div align="right">
                    <a href="https://apps.apple.com/us/app/baby-watcher-eye-wi-fi/id930583309"
                       target="_blank"
                       rel="noopener">
                        <img src="/app_store.png" alt="itunes" height="32px" hspace="5" vspace="5"/>
                    </a>

                    <a href="https://play.google.com/store/apps/details?id=com.ansol.selfiepro" target="_blank"
                       rel="noopener">
                        <img src="/google_play.svg" alt="google play" height="32px" hspace="5" vspace="5"/>
                    </a>
                </div>
            </div>
            <br/>
            <div className="card" id="shell4">
                <div id="content">
                    <div>
                        <img style={{borderRadius: '10%'}} src="/babytube.png" align="left" hspace="10" vspace="10"
                             alt="app icon"
                             width="128px"/>
                        <br/>
                        <p align="left"><h2>BabyTube Internet Video player</h2></p>
                        <p align="left"><h3>Made for Parents & Babies</h3></p>
                        <p align="left">
                            <body>
                            With BabyTube, you can relax while your child watches only the content you chose and
                            approved.
                            No Account or registration is needed!
                            </body>
                        </p>
                    </div>
                    <div className="row">
                        <div className="column">
                            <img src="/bt1.png" alt="screenshot 1" width="100%"/>
                        </div>
                        <div className="column">
                            <img src="/bt2.png" alt="screenshot 2" width="100%"/>
                        </div>
                        <div className="column">
                            <img src="/bt3.png" alt="screenshot 3" width="100%"/>
                        </div>
                    </div>
                </div>
                <div align="right">
                    <a href="https://apps.apple.com/us/app/babytube-for-parents-babies/id851093684"
                       target="_blank"
                       rel="noopener">
                        <img src="/app_store.png" alt="itunes" height="32px" hspace="5" vspace="5"/>
                    </a>
                </div>
            </div>
            <br/>
            <div className="fast_nav container">
                <ul>
                    <li className="first">
                        2023 © AnswerSolutions LLC
                    </li>
                    <li>
                        <a href="https://photodj.me">PhotoDj</a>
                    </li>
                    <li>
                        <a href="https://studycardsapp.com">StudyCards</a>
                    </li>
                    <li>
                        <a href="https://acallrecorder.com">A Call Recorder</a>
                    </li>
                    <li>
                        <a href="https://wisetalkapp.com">WiseTalk</a>
                    </li>
                    <li>
                        <a href="https://upwriteai.com">Up Write AI</a>
                    </li>
                    <li>
                        <a href="#babyTube" role="button"
                           onClick={() => _this.props.navigate('BabyTube')}>BabyTube</a>
                    </li>
                    <li>
                        <a href="#BabyMonitor" role="button"
                           onClick={() => _this.props.navigate('BabyMonitor')}>Baby Monitor</a>
                    </li>
                    <li>
                        <a href="#support" role="button"
                           onClick={() => _this.props.navigate('Support')}>Support</a>
                    </li>
                </ul>
            </div>
        </div>
    }
}

export default Products;